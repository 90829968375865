import { motion } from "framer-motion"

interface Props {
  offsetPath:
    | "projects-offset-path"
    | "client-offset-path"
    | "giving-back-offset-path"
  startingOffsetDistance: "100%" | "0%"
}

export default function CashFlow({
  offsetPath,
  startingOffsetDistance,
}: Props) {
  return (
    <>
      <motion.div
        className={offsetPath}
        initial={{ scale: 0, offsetDistance: startingOffsetDistance }}
        animate={{
          scale: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0],
          offsetDistance: startingOffsetDistance === "100%" ? "0%" : "100%",
        }}
        transition={{
          duration: 2,
          repeat: 2,

          delay: 0,
        }}
      >
        <span className="font-bold">$</span>
      </motion.div>
      <motion.div
        className={offsetPath}
        initial={{ scale: 0, offsetDistance: startingOffsetDistance }}
        animate={{
          scale: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0],
          offsetDistance: startingOffsetDistance === "100%" ? "0%" : "100%",
        }}
        transition={{
          duration: 2,
          repeat: 2,

          delay: 0.5,
        }}
      >
        <span className="font-bold">$</span>
      </motion.div>
      <motion.div
        className={offsetPath}
        initial={{ scale: 0, offsetDistance: startingOffsetDistance }}
        animate={{
          scale: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0],
          offsetDistance: startingOffsetDistance === "100%" ? "0%" : "100%",
        }}
        transition={{
          duration: 2,
          repeat: 2,

          delay: 1.5,
        }}
      >
        <span className="font-bold">$</span>
      </motion.div>
      <motion.div
        className={offsetPath}
        initial={{ scale: 0, offsetDistance: startingOffsetDistance }}
        animate={{
          scale: [0, 1, 1, 1, 1, 1, 1, 1, 1, 0],
          offsetDistance: startingOffsetDistance === "100%" ? "0%" : "100%",
        }}
        transition={{
          duration: 2,
          repeat: 2,
          delay: 1,
        }}
      >
        <span className="font-bold">$</span>
      </motion.div>
    </>
  )
}
