"use client"

import { motion } from "framer-motion"

interface Props {
  isLessHeight?: boolean
}

export default function ClickableBackground({isLessHeight}: Props) {
  return (
    <div className={`absolute -top-0 left-1/2 h-full flex items-center w-[70%] -translate-x-1/2`}>
      <motion.div
        className={`${isLessHeight ? "h-[60%]" : "h-full"} w-full border-4 border-signal`}
        initial={{ rotate: 0, scale: 1 }}
        animate={{
          rotate: [0, 10, -15, 0],
          x: ["0%", `-10%`, "10%", "-10%", "0%"],
          scale: [1, 1.2, 1],
          transition: {
            duration: 10,
            ease: "linear",
            repeat: Infinity,
          },
        }}
      ></motion.div>
    </div>
  )
}
