"use client"

import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react"
import useIntersectionObserver from "@react-hook/intersection-observer"
import { OrbitControls, useAnimations } from "@react-three/drei"
import { Canvas, useLoader } from "@react-three/fiber"
import { AnimatePresence, motion } from "framer-motion"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"

interface GLTFProps {
  path: string
  scale: number
  rotation?: [number, number, number]
}

const GLTF = ({ path, scale, rotation }: GLTFProps) => {
  const { scene, animations } = useLoader(GLTFLoader, path)
  const { ref, actions, names } = useAnimations(animations, scene)

  useEffect(() => {
    let anims = []
    const anim = actions[names[0]]?.reset().play()
    names.forEach((name, i) => {
      actions[names[i]]?.reset().play()
      anims.push(name)
    })

    //TODO: cleanup on unmount
  }, [names])

  return (
    <>
      <primitive
        object={scene}
        rotation={rotation || [0, 0, 0]}
        scale={scale}
      />
    </>
  )
}

interface ModelProps {
  path: string
  scale: number
  buttonOffset: number
  buttonText: string
  content: string
  buttonTranslateX?: number
  rotation?: [number, number, number]
  paddingX?: number
}

function Handle({ load }: { load: Dispatch<SetStateAction<boolean>> }) {
  useEffect(() => {
    load(true)
    return () => load(false)
  }, [])

  return <></>
}

export default function Model({
  path,
  scale,
  buttonOffset,
  buttonText,
  content,
  buttonTranslateX,
  rotation,
  paddingX,
}: ModelProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting) {
    lockRef.current = true
  }
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="relative">
      <div
        ref={containerRef}
        className="pointer-events-none absolute h-[300vh] w-screen -translate-y-[150vh]"
      ></div>
      <div
        className="relative h-[250px] w-[150px] lg:h-[400px] lg:w-[200px] "
        style={{ transform: `translateX(${paddingX}px)` }}
      >
        <div className=" absolute top-0 h-[300px] w-[150px] translate-x-[0] cursor-grab lg:-left-[50px] lg:h-[500px] lg:w-[300px]">
          {lockRef.current && (
            <>
              <Canvas>
                <Suspense fallback={<Handle load={setIsLoading} />}> {/*FIXME: state update on navigate back */}
                  <GLTF path={path} scale={scale} rotation={rotation} />
                  <OrbitControls enableZoom={false} />
                  <ambientLight intensity={1.3} />
                  <spotLight
                    position={[20, 20, 20]}
                    angle={0.15}
                    intensity={2000}
                  />
                </Suspense>
              </Canvas>
              <div className="absolute top-0 flex w-full translate-y-[150px] justify-center text-xs lg:translate-y-[250px]">
                <AnimatePresence>
                  {isLoading ? (
                    <motion.div
                      exit={{ opacity: 0 }}
                      className=" absolute h-12  cursor-wait  w-12 animate-spin-slow border-4 border-t-4 border-white"
                    ></motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </>
          )}
        </div>

        <div
          className="absolute flex w-full justify-center"
          style={{
            bottom: buttonOffset,
            transform: `translateX(${buttonTranslateX}px)`,
          }}
        >
          <Popover>
            <PopoverTrigger title="TODO:">
              <div
                className="relative z-10"
                // data-tina-field={tinaField(block, "popoverTrigger")}
              >
                <span className="relative block text-[18px] text-white transition-transform hover:scale-110 active:scale-100">
                  <span className="relative z-10">{buttonText}</span>
                  <div className="absolute bottom-0 h-1 w-full bg-signal"></div>
                </span>
              </div>
            </PopoverTrigger>
            <PopoverContent align="center">
              <div className="max-w-[300px] px-8 py-4">{content}</div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </div>
  )
}
