export default function Icon() {
    return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1094_9)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.66667 27H24.3333C25.8061 27 27 25.8061 27 24.3333V5.66667C27 4.19391 25.8061 3 24.3333 3H5.66667C4.19391 3 3 4.19391 3 5.66667V24.3333C3 25.8061 4.19391 27 5.66667 27Z" fill="#007EBB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.6667 23.6663H20.1052V17.6004C20.1052 15.9373 19.4733 15.0079 18.1569 15.0079C16.7249 15.0079 15.9767 15.975 15.9767 17.6004V23.6663H12.5445V12.1108H15.9767V13.6673C15.9767 13.6673 17.0087 11.7577 19.4609 11.7577C21.9119 11.7577 23.6667 13.2545 23.6667 16.3501V23.6663ZM8.44982 10.5977C7.28073 10.5977 6.33337 9.64289 6.33337 8.46534C6.33337 7.28779 7.28073 6.33301 8.44982 6.33301C9.61892 6.33301 10.5657 7.28779 10.5657 8.46534C10.5657 9.64289 9.61892 10.5977 8.44982 10.5977ZM6.67756 23.6663H10.2565V12.1108H6.67756V23.6663Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1094_9">
<rect width="24" height="24" fill="white" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg>
    )
}