"use client"

import { Locale } from "@/i18n/i18n-config"
import { PageAndNavQuery, ProjectsConnectionQuery } from "@/tina/__generated__/types"
import { useTina } from "tinacms/dist/react"
import HomeHero from "@/components/registered/HomeHero"
import MountainTop from "../registered/MountainTop"
import RollingHills from "../registered/RollingHills"
import Beach from "../registered/Beach"
import Sea from "../registered/Sea"

interface Props {
  incomingData: PageAndNavQuery
  variables: {
    relativePath: string
  };
  query: string;
  locale: Locale;
  projects: ProjectsConnectionQuery
}

export default function HomePageWrapper({
  incomingData,
  variables,
  query,
  locale,
  projects
}: Props) {
  const { data } = useTina({ query, variables, data: incomingData })
  

  return (
    <main className="relative overflow-hidden">
      <div className="relative z-10">
        {data.page.blocks?.map((block, i) => {

          switch (block?.__typename) {
            case "PageBlocksHomeHero": {
              return <HomeHero key={i} block={block} locale={locale} />
            }
            case "PageBlocksMountainTop": {
              return <MountainTop key={i} block={block} locale={locale} projects={projects}/>
            }
            case "PageBlocksRollingHills": {
              return  <RollingHills key={i} block={block} locale={locale} />
            }
            case "PageBlocksBeach": {
              return  <Beach key={i} block={block} locale={locale} />
            }
            case "PageBlocksSea": {
              return  <Sea key={i} block={block} locale={locale} />
            }
          }
        })}
      </div>
    </main>
  )
}
