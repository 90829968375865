"use client"

import { useRef } from "react"
import useIntersectionObserver from "@react-hook/intersection-observer"
import { motion } from "framer-motion"

interface LazyVideoProps {
  src: string;
  tinaFieldData?: string;
  looping?: boolean;
  delay?: number;
}

export default function LazyVideo({
  src,
  tinaFieldData,
  delay,
  looping = true,
}: LazyVideoProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting) {
    lockRef.current = true
  }
  return (
    <div ref={containerRef} className="h-full w-full overflow-hidden">
      {lockRef.current && (
        <motion.div
          initial={{ scale: 0 }}
          whileInView={{ scale: 1  }}
          viewport={{once: true}}
          transition={delay ? {delay: delay} : {}}
          className="h-full w-full overflow-hidden"
        >
          <video
            className="h-full w-full object-cover "
            tina-field-data={tinaFieldData}
            src={src}
            autoPlay
            loop={looping}
            playsInline
            muted
          ></video>
        </motion.div>
      )}
    </div>
  )
}
