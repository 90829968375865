"use client"

import { motion } from "framer-motion"
import { useFormState, useFormStatus } from "react-dom"

import contactFormAction from "@/lib/contact-form-action"
import { PageAndNavQuery } from "@/tina/__generated__/types";
import { Locale } from "@/i18n/i18n-config";

interface ContactFormProps {
  isHome?: boolean;
  data:  PageAndNavQuery["footer"];
  locale: Locale
}

interface SubmitButtonProps {
  state: {
    message: string | null
    isError: boolean | null
  };
  buttonText: string | undefined | null;
  successMessage: string | undefined | null;
  waitingMessage: string | undefined | null;
  errorMessage: string | undefined  | null;
}

const SubmitButton = ({ state, buttonText, successMessage, waitingMessage, errorMessage }: SubmitButtonProps) => {
  const { pending } = useFormStatus()

  return (
    <div className="flex gap-4 items-center">
      <button
        aria-disabled={pending}
        disabled={pending || state?.isError === false}
        className={` ${state?.isError === false ? "!scale-100 cursor-not-allowed" : ""} relative flex transition-transform hover:scale-110 active:scale-100 disabled:opacity-50 `}
        type={"submit"}
      >
        <div className="relative z-10 ">
          <span className="text-[20px]">{buttonText}</span>
        </div>
        <div className="absolute -top-0 left-1/2 h-full w-[70%] -translate-x-1/2 ">
          <motion.div
            className="h-full w-full border-4 border-signal"
            initial={{ rotate: 0, scale: 1 }}
            animate={{
              rotate: [0, 10, -15, 0],
              x: ["0%", `-10%`, "10%", "-10%", "0%"],
              scale: [1, 1.2, 1],
              transition: {
                duration: 10,
                ease: "linear",
                repeat: Infinity,
              },
            }}
          ></motion.div>
        </div>
      </button>
      <div className="font-var-h2 font-roboto">
      {pending ? <p className="animate-pulse">{waitingMessage}</p> : null}
      {state && state.isError ? (
        <span className="text-red-400">{errorMessage}</span>
      ) : null}
      { state && state.isError === false ? (
        <span className="">{successMessage}</span>
      ) : null}
      </div>
    </div>
  )
}

const initialState = {
  message: null,
  isError: null,
}

const ContactForm = ({isHome, data, locale}: ContactFormProps) => {

  //@ts-ignore TODO: remove this or check why this causes overload error w initial state
  const [state, formAction] = useFormState(contactFormAction, initialState)

  return (
    <form action={formAction} aria-disabled={state.isError === false} className="flex flex-col items-center sm:items-start">
      <h3 className="font-var-h2 relative block z-10 font-roboto text-[20px] font-bold text-center sm:text-left">
        {data.contactFormTitle?.[locale]}
      </h3>
      <p className="mb-4">
        {data.contactFormSubline?.[locale]}{" "}
        <a
          href="mailto:hi@adlerlagune.com"
          className="inline-block underline decoration-signal decoration-4 underline-offset-4 transition-transform hover:scale-110 active:scale-100"
        >
          email
        </a>
      </p>
      <input readOnly className="hidden" name="locale" value={locale}></input>
      <div className="relative flex flex-col gap-2">
        <div className="relative z-10">
          <input
            placeholder={`${data.contactFormName?.[locale]}*`}
            disabled={state.isError === false}
            required
            name="name"
            className={`relative z-10 h-10 w-[250px] rounded-[5px] ${ isHome ?`bg-white` : " border-[2px] bg white border-jet"} px-4 transition-transform ${state.isError === false ? "!scale-100 opacity-50 cursor-not-allowed" : "" } placeholder:text-jet hover:scale-110 focus:scale-110 focus:outline-jet active:scale-100`}
          ></input>
        </div>
        <div className="relative z-10  w-[250px]">
          <input
            disabled={state.isError === false}
            placeholder={`${data.contactFormEmail?.[locale]}*`}
            required
            name="email"
            type="email"
            className={`relative z-10 h-10 w-full rounded-[5px] ${ isHome ?`bg-white` : " border-[2px] bg white border-jet"} ${state.isError === false ? "!scale-100 opacity-50 cursor-not-allowed" : "" } px-4 transition-transform placeholder:text-jet hover:scale-110 focus:scale-110 focus:outline-jet active:scale-100`}
          ></input>
        </div>

        <div className="relative z-10 flex flex-col">
          <div>
            <select
              disabled={state.isError === false}
              required
              name="type"
              className={`relativez-10 h-10 w-[250px] sm:w-auto appearance-none rounded-[5px] ${ isHome ?`bg-white` : " border-[2px] bg white border-jet"} ${state.isError === false ? "!scale-100 opacity-50 cursor-not-allowed" : "" } px-4 transition-transform placeholder:text-jet hover:scale-110 focus:scale-110  focus:outline-jet active:scale-100`}
            >
              <option value="">{data.contactFormType?.[locale]}*</option>
              <option value="project">{data.contactFormTypeProject?.[locale]}</option>
              <option value="application">{data.contactFormTypeApply?.[locale]}</option>
              <option value="question">{data.contactFormTypeOther?.[locale]}</option>
            </select>
          </div>
        </div>
        <div className="relative z-10 ">
          <textarea
          disabled={state.isError === false}
            name="message"
            placeholder={data.contactFormMessage?.[locale] || ""}
            className={`relative z-10 h-10  w-[250px] appearance-none rounded-[5px] ${ isHome ?`bg-white` : " border-[2px] bg white border-jet"} ${state.isError === false ? "!scale-100 hover:opacity-50 cursor-not-allowed" : "" } px-4 py-2 opacity-50 transition-opacity placeholder:text-jet hover:opacity-100 focus:opacity-100 focus:outline-jet`}
          ></textarea>
        </div>
        <div className="relative flex items-center gap-2">
          <input
          disabled={state.isError === false}
            name="accepts"
            required
            type="checkbox"
            className={`check group h-6 w-6 cursor-pointer appearance-none rounded-[5px] ${ isHome ?`bg-white` : "border-[2px] bg white border-jet"} ${state.isError === false ? "!scale-100 opacity-50 cursor-not-allowed" : "" } transition-transform hover:scale-110 focus:scale-110 focus:outline-jet active:scale-100 `}
          ></input>
          <label>{data.contactFormAccept?.[locale]}*</label>
        </div>
        <div className={`${state.isError === false ? "cursor-not-allowed" : ""} my-4 flex justify-center sm:justify-start`}>
          <SubmitButton state={state} buttonText={data.contactFormButton?.[locale]} waitingMessage={data.contactFormWaiting?.[locale]} successMessage={data.contactFormSuccess?.[locale]} errorMessage={data.contactFormFailure?.[locale]} />
        </div>
      </div>
    </form>
  )
}

export default ContactForm
