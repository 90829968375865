"use client"

import { useState } from "react"
import { Locale } from "@/i18n/i18n-config"
import { PageBlocksBeach } from "@/tina/__generated__/types"
import { motion } from "framer-motion"
import { tinaField } from "tinacms/dist/react"
import { useInterval } from "usehooks-ts"

import CashFlow from "../static/CashFlow"
import LazyVideo from "../static/LazyVideo"
import Button from "../ui/Button"

interface BeachProps {
  block: PageBlocksBeach
  locale: Locale
}

export default function Beach({ block, locale }: BeachProps) {
  const [isDraining, setIsDraining] = useState(false)

  useInterval(() => {
    setIsDraining(!isDraining)
  }, 7500)

  return (
    <div className="relative z-10 flex flex-col ">
      <section className="relative z-10 flex flex-col items-center bg-[#F6EECF] lg:translate-y-[25px]">
        <div className="w-full 2xl:max-w-[1500px] 2xl:translate-y-20">
          <div className=" z-10 flex gap-20  px-[5vw] pt-[150px] sm:pt-[200px] lg:px-[10vw]  lg:pt-[300px] 2xl:px-0 2xl:pt-[450px] ">
            <div className="relative z-10 w-full text-center sm:w-auto sm:text-left">
              <h2
                data-tina-field={tinaField(block, "headline")}
                className="font-var-h2 font-roboto text-[32px] font-bold lg:text-[40px]"
              >
                {block.headline}
              </h2>
              <p
                data-tina-field={tinaField(block, "sublineLineOne")}
                className="text-[23px] leading-snug"
              >
                {block.sublineLineOne}
              </p>
              <p
                data-tina-field={tinaField(block, "sublineLineTwo")}
                className="text-[23px] leading-snug"
              >
                {block.sublineLineTwo}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center text-center sm:item-start sm:text-left gap-40 px-[5vw] lg:flex-row lg:justify-between lg:gap-[10vw] lg:px-[10vw] xl:gap-[20vw] 2xl:justify-start 2xl:px-0 ">
            <div className="  pt-[10px] w-full sm:block flex flex-col items-center text-[20px]  lg:text-[23px]">
              <div className="flex max-w-[300px] items-center gap-1.5 lg:max-w-none ">
                <p className="text-left translate-x-[5vw] sm:translate-x-0">
                  adlerlagune<span className="mx-1"></span>
                  <img
                    src="/images/100p.webp"
                    alt="adlerlagune logo"
                    className="relative inline h-[35px] w-[35px] -translate-y-1 "
                  />
                  <span
                    data-tina-field={tinaField(block, "clientSentence")}
                    className="test-test inline"
                  >
                    <span className="mx-1"></span>
                    {block.clientSentence}
                  </span>
                </p>
                <div className="absolute translate-x-[20px] translate-y-5 lg:translate-x-[30%] lg:translate-y-8">
                  <svg
                    className="hidden lg:block"
                    width="254"
                    height="131"
                    viewBox="0 0 254 131"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.5 118.5C1.5 118.5 100.5 70.0598 180.5 118.5C260.5 166.94 250 0.999992 250 0.999992" />
                  </svg>
                  <svg
                    className="absolute block lg:relative lg:hidden"
                    width="107"
                    height="94"
                    viewBox="0 0 107 94"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M40.1001 90.9475C40.1001 90.9475 103.358 55.0653 103.742 34.9887C104.108 15.805 49.6096 21.4538 49.6096 21.4538C40.0999 24.0141 3.88984 25.5503 3.88984 0.971371"
                      strokeWidth="6.03004"
                      strokeDasharray="10 10"
                    />
                  </svg>

                  {!isDraining ? (
                    <CashFlow
                      startingOffsetDistance="100%"
                      offsetPath="client-offset-path"
                    />
                  ) : null}
                </div>
              </div>

              <div className="mt-[80px] lg:mt-[50px]">
                <div className="absolute translate-y-6">
                  <svg
                    className="hidden lg:absolute lg:block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="451"
                    height="437"
                  >
                    <path
                      d="M12.7991 1.99902C12.7991 1.99902 94.3616 116.13 24.5426 97.28C-45.2764 78.4296 73 208.5 73 208.5"
                      fill="transparent"
                    />
                  </svg>

                  <svg
                    className="absolute block lg:hidden"
                    width="63"
                    height="155"
                    viewBox="0 0 63 155"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M58.4578 2.3124C58.4578 2.3124 72.8278 24.8939 20.4807 64.9246C-31.8665 104.955 50.2467 152.17 50.2467 152.17"
                      strokeWidth="6.03004"
                      strokeDasharray="10 10"
                    />
                  </svg>

                  {isDraining ? (
                    <CashFlow
                      startingOffsetDistance="0%"
                      offsetPath="giving-back-offset-path"
                    />
                  ) : null}
                </div>
                <div className="absolute translate-x-[90px] translate-y-6 lg:translate-x-[15%]">
                  <svg
                    className="absolute hidden lg:relative lg:block"
                    width="355"
                    height="124"
                    viewBox="0 0 355 124"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.500137 2.50009C0.500137 2.50009 80.1107 7.11725 130.5 15.0005C190.074 24.3205 144.5 98.5008 215.5 92.5009C286.5 86.5011 343 83.4991 352 122.5" />
                  </svg>
                  <svg
                    className="absolute block lg:hidden"
                    width="134"
                    height="294"
                    viewBox="0 0 134 294"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.47611 6.07085C1.47611 6.07085 140.321 -22.1495 130.054 124.932C119.788 272.014 83.8657 291.212 83.8657 291.212"
                      strokeWidth="6.03004"
                      strokeDasharray="10 10"
                    />
                  </svg>

                  {isDraining ? (
                    <CashFlow
                      startingOffsetDistance="0%"
                      offsetPath="projects-offset-path"
                    />
                  ) : null}
                </div>

                <div className="flex ">
                  <div className="relative flex overflow-hidden rounded-[10px] border-4 border-jet uppercase">
                    <p className="relative z-10  px-2">{block.funds}</p>
                    <motion.div
                      className="absolute top-0 h-full w-full origin-left bg-[#FF4D4D]"
                      initial={{ scaleX: "20%" }}
                      animate={
                        isDraining
                          ? {
                              scaleX: "20%",
                              backgroundColor: [
                                "#2DDA3F",
                                "#2DDA3F",
                                "#FFEC42",
                                "#FF4D4D",
                                "#FF4D4D",
                              ],
                            }
                          : {
                              scaleX: "90%",
                              backgroundColor: [
                                "#FF4D4D",
                                "#FF4D4D",
                                "#FFEC42",
                                "#2DDA3F",
                                "#2DDA3F",
                              ],
                            }
                      }
                      transition={
                        isDraining
                          ? { duration: 7 }
                          : { delay: 1.5, duration: 7 }
                      }
                    ></motion.div>
                  </div>
                </div>
                <div className="relative mt-[40px] flex flex-wrap lg:mt-[100px]">
                  <div className="relative mt-[100px] ">
                    <Button
                      tinaFieldData={tinaField(block, "givingBackText")}
                      isExternal={false}
                      buttonText={block.givingBackText || "giving back"}
                      link={"giving-back"}
                      locale={locale}
                    />
                    <div className="absolute h-[50px] w-[70px] -translate-y-[100px] translate-x-[80px] lg:translate-x-[60px] lg:translate-y-[30px]">
                      <LazyVideo
                        tinaFieldData={tinaField(block, "givingBackVidOne")}
                        src={block.givingBackVidOne || ""}
                      />
                    </div>
                    <div className="absolute h-[50px] w-[70px] translate-x-2 translate-y-4 lg:-translate-y-[20px] lg:translate-x-[150px]">
                      <LazyVideo
                        tinaFieldData={tinaField(block, "givingBackVidTwo")}
                        src={block.givingBackVidTwo || ""}
                      />
                    </div>
                  </div>

                  <div className="relative mb-20 mt-[230px] lg:mb-0 lg:ml-[200px] lg:mt-0">
                    <Button
                      tinaFieldData={tinaField(block, "projectsText")}
                      isExternal={false}
                      buttonText={
                        block.projectsText || "build our own projects"
                      }
                      link={"projects"}
                      queryParams={{ type: "originals" }}
                      locale={locale}
                    />
                    <div className="absolute h-[50px] w-[70px] translate-y-[20px] lg:-translate-y-[120px] lg:translate-x-[120px]">
                      <LazyVideo
                        tinaFieldData={tinaField(block, "projectsVidOne")}
                        src={block.projectsVidOne || ""}
                      />
                    </div>
                    <div className="absolute h-[50px] w-[70px] translate-x-[100px] translate-y-[30px] lg:translate-x-[200px] ">
                      <LazyVideo
                        tinaFieldData={tinaField(block, "projectsVidTwo")}
                        src={block.projectsVidTwo || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden sm:block">
              <div className="relative flex lg:mt-24 justify-center sm:justify-start">
                <div className="relative">
                  <h3
                    className="font-var-h2 relative block font-roboto text-[20px] font-bold"
                    data-tina-field={tinaField(block, "shopHeadline")}
                  >
                    {block.shopHeadline}
                  </h3>
                  <span className="absolute -right-4 block -translate-y-[80px] rotate-12 text-[40px] font-semibold text-green-400">
                    $
                  </span>
                  <span className="absolute -right-9 block -translate-y-14 rotate-[32deg] text-[32px] font-semibold text-green-400">
                    $
                  </span>
                </div>
              </div>

              <div className=" ">
                <div className="relative flex items-center gap-1 py-1">
                  <span className="cursor-wait opacity-50">
                    shop.adlerlagune.com{" "} 
                  </span>
                  <div
                    data-tina-field={tinaField(block, "comingSoon")}
                    className="top-0 text-[10px] whitespace-nowrap  rounded-sm bg-black px-1  text-signal "
                  >
                    {block.comingSoon}
                  </div>
                </div>
              </div>
              <p
                className="max-w-[60vw] lg:max-w-[20vw] flex justify-center"
                data-tina-field={tinaField(block, "shopText")}
              >
                {block.shopText}
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="bottom-0 left-0 z-0 flex w-[120vw] flex-col bg-[rgb(203,238,240)] 2xl:-translate-x-10">
        <svg
          viewBox="0 0 1283 278"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M443 277.5C245.294 277.5 -0.000190735 174 0 0H1282.5C1282.5 0 669.188 277.5 443 277.5Z"
            fill="#F7EECF"
          />
        </svg>
      </div>
    </div>
  )
}
