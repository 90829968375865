"use client"

import { Locale } from "@/i18n/i18n-config"
import { PageBlocksSea } from "@/tina/__generated__/types"
import { tinaField } from "tinacms/dist/react"

import LazyVideo from "../static/LazyVideo"
import Button from "../ui/Button"
import ClickableBackground from "../ui/ClickableBackground"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"

interface SeaProps {
  block: PageBlocksSea
  locale: Locale
}

export default function Sea({ block, locale }: SeaProps) {
  return (
    <div className="relative z-0 flex flex-col bg-[rgb(203,238,240)] 2xl:items-center">
      <section className="mt-[100px] flex flex-col pb-[100px] 2xl:w-full max-w-[1500px]  lg:pb-[200px] items-center text-center sm:items-start sm:text-left">
        <div className=" relative z-10 flex gap-20 2xl:px-0  px-[5vw] lg:px-[10vw] ">
          <div className="relative z-10">
            <h2
              data-tina-field={tinaField(block, "headline")}
              className="font-var-h2  font-roboto text-[32px] font-bold lg:text-[40px]"
            >
              {block.headline}
            </h2>
            <p
              data-tina-field={tinaField(block, "subline")}
              className="text-[20px] leading-snug lg:text-[23px]"
            >
              {block.subline}
            </p>
          </div>
        </div>

        <div className="mt-20 relative z-10 px-[5vw] text-[23px] lg:px-[10vw] 2xl:px-0 ">
          <ul className="flex w-full  flex-wrap gap-20 pl-[20px] flex-col sm:flex-row">
            {block.people?.map((item, i) => (
              <li key={i} className={` relative flex justify-start`}>
                <div>
                  <div className="h-[255px] w-[255px] overflow-hidden rounded-[15px] bg-white">
                    <LazyVideo src={item?.video || ""} looping={false} />
                  </div>

                  <div className="relative mt-4 flex w-[280px] items-center justify-between">
                    <div className="flex flex-col items-start">
                      <div className="font-var-h2 mt-3 flex h-[30px] items-center gap-0.5 font-roboto text-[26px] leading-none lg:text-[26px]">
                        {item?.name}
                        {item?.socials ? (
                          <div>
                            <Popover>
                              <PopoverTrigger title="socials">
                                <div
                                  className="relative z-10 translate-y-0.5"
                                  // data-tina-field={tinaField(block, "popoverTrigger")}
                                >
                                  <div className="relative z-20 block text-[18px]  text-white transition-transform hover:scale-110 active:scale-100">
                                    <div className="relative z-20 px-1 pt-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 -960 960 960"
                                        className="h-[20px] w-[20px] lg:h-[30px] lg:w-[30px]"
                                      >
                                        <path d="M261.652-221.347 186.999-296l371-370.999H232.652v-106.002h506.001v506.002H632.652v-325.347l-371 370.999Z" />
                                      </svg>
                                    </div>
                                    <ClickableBackground />
                                  </div>
                                </div>
                              </PopoverTrigger>
                              <PopoverContent align="center" side="top">
                                <ul className="flex max-w-[300px] flex-col items-center  gap-4 px-8 pb-7 pt-6 text-[20px]">
                                  {item?.socials?.map((social, i) => (
                                    <li key={social?.linkName || i}>
                                      <a
                                        className="relative block transition-transform hover:scale-110 active:scale-100"
                                        href={social?.link || ""}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                      >
                                        <span className="relative z-10">
                                          {social?.linkName}
                                        </span>
                                        <div className="absolute bottom-0 h-1 w-full bg-signal"></div>
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </PopoverContent>
                            </Popover>
                          </div>
                        ) : null}
                      </div>

                      <p className="-translate-y-[1.5] text-[18px]">
                        {item?.position}
                      </p>
                    </div>

                    <div className="flex justify-end">
                      <div className="h-[98px]  w-[98px]  overflow-hidden rounded-full ">
                        <LazyVideo src={item?.facecam || ""} />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-32 lg:mt-40 relative z-10 px-[5vw] lg:px-[10vw] 2xl:px-0 ">
          <div className="max-w-[418px] leading-snug">
            <p className="" data-tina-field={tinaField(block, "wantToJoin")}>
              {block.wantToJoin}
            </p>
            <p data-tina-field={tinaField(block, "youAreEarly")}>
              {block.youAreEarly}
            </p>
            <p
              className="mt-4"
              data-tina-field={tinaField(block, "notActivelyHiring")}
            >
              {block.notActivelyHiring}
            </p>

            <div className="my-10 flex justify-center sm:justify-start">
              <div>
                <Button
                  locale={locale}
                  isExternal={false}
                  link={"career"}
                  tinaFieldData={tinaField(block, "joinButtonText")}
                  buttonText={block.joinButtonText || "join us"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute -bottom-1 z-0 left-0 w-full ">
          <svg
            viewBox="0 0 1284 258"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M619.5 177C319.1 271 81.6667 98.5 0.5 0.5V257.5H1283.5V177C1187.33 137.833 919.9 83 619.5 177Z"
              fill="#A9DEE4"
            />
          </svg>
        </div>
      </section>
    </div>
  )
}
