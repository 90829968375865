"use client"

import Link from "next/link"
import { motion } from "framer-motion"
import { Locale } from "@/i18n/i18n-config"

interface ButtonProps {
  link: string
  isExternal: boolean
  tinaFieldData?: string
  buttonText: string
  queryParams?: {[key: string]: string},
  locale?: Locale
}


export default function Button({
  link,
  isExternal,
  tinaFieldData,
  buttonText,
  queryParams,
  locale
}: ButtonProps) {
  if (isExternal) {
    return (

      <div className=" flex my-4">
      <a
        className="relative flex transition-transform hover:scale-110 active:scale-100"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="relative z-10 " data-tina-field={tinaFieldData}>
          <span className="text-[20px]">{buttonText || "Click me"}</span>
        </div>
        <div className="absolute -top-0 left-1/2 h-full w-[70%] -translate-x-1/2 ">
          <motion.div
            className="h-full w-full border-4 border-signal"
            initial={{ rotate: 0, scale: 1 }}
            animate={{
              rotate: [0, 10, -15, 0],
              x: ["0%", `-10%`, "10%", "-10%", "0%"],
              scale: [1, 1.2, 1],
              transition: {
                duration: 10,
                ease: "linear",
                repeat: Infinity,
              },
            }}
          ></motion.div>
        </div>
      </a>
      </div>
    )
  }

  return (
    <div className="flex">
    <Link
      className="relative flex transition-transform hover:scale-110 active:scale-100"
      href={queryParams === undefined ? `/${locale}/${link}` : {pathname: `/${locale}/${link}`, query: queryParams}} //FIXME:: chck client error if ever happens again -> maybe change to <a> (this also happened on legal notice page link)
      data-tina-field={tinaFieldData}
    >
      <div className="relative z-10 " data-tina-field={tinaFieldData}>
        <span className="text-[20px]">{buttonText || "Click me"}</span>
      </div>
      <div className="absolute -top-0 left-1/2 h-full w-[70%] -translate-x-1/2 ">
        <motion.div
          className="h-full w-full border-4 border-signal"
          initial={{ rotate: 0, scale: 1 }}
          animate={{
            rotate: [0, 10, -15, 0],
            x: ["0%", `-10%`, "10%", "-10%", "0%"],
            scale: [1, 1.2, 1],
            transition: {
              duration: 10,
              ease: "linear",
              repeat: Infinity,
            },
          }}
        ></motion.div>
      </div>
    </Link>
    </div>
  )
}
