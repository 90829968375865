"use client"

import { Locale } from "@/i18n/i18n-config"
import { PageBlocksRollingHills } from "@/tina/__generated__/types"
import { tinaField } from "tinacms/dist/react"

import Model from "../static/Model"

interface RollingHillsProps {
  block: PageBlocksRollingHills
  locale: Locale
}

export default function RollingHills({ block, locale }: RollingHillsProps) {
  return (
    <div className="relative z-20 flex flex-col">
      <section className="pt-[50px] lg:pt-[200px]">
        <div className="flex gap-20 sm:px-[5vw] pt-[100px] lg:px-[10vw] 2xl:px-0 2xl:flex 2xl:flex-col 2xl:items-center">
          <div className=" text-white 2xl:w-full w-full text-center sm:w-auto sm:text-left translate-y-20 z-10 sm:translate-y-0 2xl:max-w-[1500px]">
            <h2
              data-tina-field={tinaField(block, "hillsHeadline")}
              className="font-var-h2  font-roboto text-[32px] font-bold lg:text-[40px]"
            >
              {block.hillsHeadline}
            </h2>
            <p
              data-tina-field={tinaField(block, "hillsSublineLineOne")}
              className="text-[20px] leading-snug lg:text-[23px]"
            >
              {block.hillsSublineLineOne}
            </p>
            <p
              data-tina-field={tinaField(block, "hillsSublineLineTwo")}
              className="text-[23px] leading-snug"
            >
              {block.hillsSublineLineTwo}
            </p>
          </div>
        </div>

        <div className="relative bg-[#128610] 2xl:flex 2xl:flex-col 2xl:items-center">
          <div className="h-[80%] w-[12vw] absolute right-4 top-[10%] flex sm:hidden items-center justify-center flex-wrap text-white/50">
          <p className=" text-center text-xs border-white/10 border-l-2 pl-1">swipe here to scroll</p>
            <p className=" text-center text-xs border-white/10 border-l-2 pl-1">swipe here to scroll</p>
            <p className=" text-center text-xs border-white/10 border-l-2 pl-1">swipe here to scroll</p>
          </div>
          <div className="h-full  2xl:max-w-[1500px] w-full">
            <div className="relative z-20 py-20 sm:py-0 flex h-full flex-wrap gap-y-10 px-[10px] pb-10 lg:gap-0 lg:px-[7vw] 2xl:px-0">
              <Model
                path={"/models/__web__-adlerlagune-plants-anim.gltf"}
                scale={0.55}
                buttonOffset={-20}
                content={block.webContent || "TODO"}
                buttonText={block.webButtonText || "TODO"}
              />
              <Model
                path={"/models/__2D__-adlerlagune-plants-anim.gltf"}
                scale={0.45}
                buttonOffset={20}
                content={block.twoContent || "TODO"}
                buttonText={block.twoButtonText || "TODO"}
              />
              <Model
                path={"/models/__native__-adlerlagune-plants-anim.gltf"}
                scale={0.55}
                buttonTranslateX={-5}
                rotation={[0.3, 0.2, 0]}
                buttonOffset={-20}
                content={block.nativeContent || "TODO"}
                buttonText={block.nativeButtonText || "TODO"}
              />
              <Model
                path={"/models/__3D__-adlerlagune-plants-anim-var.gltf"}
                buttonTranslateX={-5}
                scale={0.65}
                rotation={[0.2, -1.8, 0]}
                buttonOffset={20}
                content={block.threeContent || "TODO"}
                buttonText={block.threeButtonText || "TODO"}
              />
              <Model
                path={
                  "/models/__internationalization__-adlerlagune-plants-anim.gltf"
                }
                buttonTranslateX={10}
                scale={0.65}
                buttonOffset={20}
                content={block.i18nContent || "TODO"}
                buttonText={block.i18nButtonText || "TODO"}
              />
              <Model
                path={"/models/__games__-adlerlagune-plants-anim.gltf"}
                buttonTranslateX={0}
                scale={0.35}
                buttonOffset={20}
                rotation={[0.2, -1.5, 0]}
                paddingX={20}
                content={block.gamesContent || "TODO"}
                buttonText={block.gamesButtonText || "TODO"}
              />

              {/*scroll guard for 3d models*/}
              <div className="absolute right-0 z-10 h-full w-[30vw] md:hidden "></div>
            </div>
          </div>
        </div>
        <div className="absolute right-0 h-[200px] w-full -translate-y-0.5 pointer-events-none">
          <svg
            className="super-xl-hidden"
            viewBox="0 0 1270 367"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1115_35)">
              <path
                d="M0 -4V57V314.5C14.1667 260.833 89.7 146.7 278.5 119.5C467.3 92.3 1025.34 66.5 1280.76 57L1280 -4H0Z"
                fill="#128610"
              />
            </g>
            <defs></defs>
          </svg>
          <svg
            className="super-xl-visible"
            viewBox="0 0 3152 367"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1523_7)">
              <path
                d="M0 -4V57V314.5C14.1667 260.833 89.7 146.7 278.5 119.5C467.3 92.3 2897.24 66.5 3152.66 57L3151.9 -4H0Z"
                fill="#128610"
              />
            </g>
            <defs>
              <clipPath id="clip0_1523_7">
                <rect width="3152" height="367" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </section>

      <div className="absolute right-0 -z-10 flex w-full flex-col xl:top-0">
        <svg
          className="hidden sm:block 2xl:hidden"
          viewBox="0 0 1280 493"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M197.236 11.3214C96.8345 -14.4846 28.8038 13.0092 0 30.7191V316L1318.53 298.5V125.171C1201.25 119.478 1007.86 202.968 849.436 225.738C691.015 248.508 322.738 43.5789 197.236 11.3214Z"
            fill="#0A6708"
          />
          <path
            d="M949.35 276.199C720.687 377.688 374.723 85 95.5755 85C61.553 85 29.6949 92.2772 0 105.169V493H1285V220.215C1173.96 213.986 1062.2 226.112 949.35 276.199Z"
            fill="#128610"
          />
        </svg>
        <svg
          className="md:hidden"
          viewBox="0 0 390 282"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.14441e-05 0.737305L0 56.3202V158.735L391.844 158.735V96.5104C391.844 96.5104 356.346 109.873 298.132 109.872C230.779 109.872 1.14441e-05 0.737305 1.14441e-05 0.737305Z"
            fill="#0A6708"
          />
          <path
            d="M0 281.46V58.1719C138.543 108.803 281.35 177.835 391.975 153.354V281.717L0 281.46Z"
            fill="#128610"
          />
        </svg>
        <svg
          className="super-xl-hidden hidden 2xl:block "
          viewBox="0 0 1985 701"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M304.683 11.3214C149.669 -14.4846 44.6346 13.0092 0.163574 30.7191V373.052L1984.14 349.779V119.399C1803.08 113.706 1556.22 202.968 1311.63 225.738C1067.04 248.508 498.449 43.5789 304.683 11.3214Z"
            fill="#0A6708"
          />
          <path
            d="M1465.89 289.039C1112.85 384.435 578.71 109.319 147.726 109.319C95.1973 109.319 46.0104 116.16 0.163574 128.277V700.164H1981.01L1984.12 236.416C1812.68 230.561 1640.13 241.959 1465.89 289.039Z"
            fill="#128610"
          />
        </svg>

        <svg
          className="super-xl-visible"
          viewBox="0 0 3201 701"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M491.88 11.3942C241.855 -14.4119 72.4423 13.082 0.713867 30.7919V373.125L3200.71 349.852V119.471C2908.68 113.779 2510.52 203.041 2116.02 225.811C1721.51 248.581 804.411 43.6517 491.88 11.3942Z"
            fill="#0A6708"
          />
          <path
            d="M2364.83 289.111C1795.4 384.508 933.865 109.392 238.72 109.392C153.996 109.392 74.6614 116.232 0.713867 128.35V700.237H3195.68L3200.68 236.489C2924.17 230.634 2645.85 242.032 2364.83 289.111Z"
            fill="#128610"
          />
        </svg>
      </div>
    </div>
  )
}
