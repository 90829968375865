"use client"

import { useRef } from "react"
import { PageBlocksHomeHero } from "@/tina/__generated__/types"
import { motion } from "framer-motion"
import { tinaField } from "tinacms/dist/react"
import ContactButton from "../static/ContactButton"
import LazyVideo from "../static/LazyVideo"
import { Locale } from "@/i18n/i18n-config"

interface HomeHeroProps {
  block: PageBlocksHomeHero;
  locale: Locale;
}

export default function HomeHero({block}: HomeHeroProps) {

  const parentRef = useRef<HTMLHeadingElement>(null)

  return (
    <div className="w-full flex justify-center">
    <section className="mt-[20vh] 2xl:max-w-[1500px] flex flex-col items-center lg:mx-[10vw] lg:flex-row lg:items-start lg:gap-[30vw]">
      <div>
        <h1 className="relative flex -translate-x-3 flex-col text-[50px] tracking-[2px] lg:text-[80px]">
          <p className="font-roboto font-var-heading leading-[80%]">We</p>
          <p className="font-var-heading font-roboto leading-[87%]" ref={parentRef}>make</p>
          <p className="font-var-heading font-roboto leading-[87%]">things</p>

          <motion.img
            src="/images/bird.gif"
            alt="animated minimalist bird"
            className="absolute -right-10 top-10 h-[56px] w-[63px]"
            initial={{ x: 0, y: 0 }}
            animate={{
              x: [0, -50, 40, 0],
              y: [0, 10, 20, 10, 0],
              scaleX: [1, 1, 1, 1, -1, -1, -1, -1, 1, 1, 1,]
            }}
            transition={{ duration: 5, repeat: Infinity, ease: "linear", }}
          />

          <motion.img
            src="/images/bird.gif"
            alt="animated minimalist bird"
            className="absolute top-24 -left-20 h-[56px] w-[63px] scale-x-[-1]"
            initial={{ x: 0, y: 0, scaleX: -1 }}
            animate={{
              x: [0, 50, -40, 0],
              y: [0, 10, 20, 10, 0],
              scaleX: [-1, -1, -1, -1, 1, 1, 1, 1, -1, -1,  -1,]
            }}
            transition={{ duration: 5, delay: .5, repeat: Infinity, ease: "linear" }}
          />
          
        </h1>
        <div className="my-4 flex flex-col font-roboto text-[23px] lg:my-[30px]">
          <div className="max-w-[196px] leading-[115%] lg:max-w-none">
            <p className="flex" data-tina-field={tinaField(block, "subline")}>
              {block.subline}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <ContactButton 
            email={block.email || "adlerlagune@gmail.com"}
            emailLabel={block.emailLabel || "email"}
            meetingLabel={block.meetingLabel || "meeting"}
            meetingLink={block.meetingLink || "link"}
            buttonText={block.contactButtonText || "contact us"}
            tinaFieldData={tinaField(block, "contactButtonText")}
          />
        </div>
      </div>

      <div className="relative flex aspect-square justify-center lg:justify-start mt-[50px] lg:mt-0 h-[200px] xl:h-[400px]">
        <div className="z absolute z-20 h-[182px] w-[90px] lg:h-[351px] lg:w-[182px] lg:-translate-y-1/4 rotate-[12deg]">
          <LazyVideo delay={0.4} src={block.centerVideo || ""} tinaFieldData={tinaField(block, "centerVideo")}/>
        </div>
        <div className="absolute left-[60%] lg:left-[20%] z-10 w-[150px] h-[90px] lg:h-[184px] lg:w-[299px] translate-y-[100%] rotate-[5deg]">
          <LazyVideo delay={0.5} src={block.rightVideo || ""} tinaFieldData={tinaField(block, "rightVideo")}/>
        </div>
        <div className="absolute h-[90px] w-[90px]  lg:h-[184px] lg:w-[184px] -translate-x-[100%] translate-y-[75%] rotate-[-20deg]">
           <LazyVideo delay={0.6} src={block.leftVideo || ""} tinaFieldData={tinaField(block, "leftVideo")}/>
        </div>
      </div>
    </section>
    </div>
  )
}
